.registerCard {
  @apply flex flex-col justify-center items-center transition-all border border-innerBorder rounded-lg p-4 w-full gap-4;
  min-height: 84px;

  &Active {
    @apply border-none bg-grey900;

    .registerCardTitle {
      @apply text-primary500 font-semibold text-base;
    }

    .registerCardStepNum {
      @apply border-primary500 text-primary500;
    }
  }

  &Content {
    @apply flex justify-between items-center w-full h-full;
  }

  &Check {
    @apply flex text-white font-medium text-sm gap-2;
  }

  &Info {
    @apply flex items-center gap-6;
  }

  &StepNum {
    @apply flex justify-center items-center border border-white rounded-full w-8 h-8 text-white font-semibold text-xs;
  }

  &Title {
    @apply text-grey600 text-base;
  }
}

.keyGenerationGuide {
  @apply text-sm text-grey300;

  a {
    @apply text-primary;
  }
}
