.spinner {
  transform: rotateZ(0deg);
  animation: loader 3s infinite linear;
}

@keyframes loader {
  0% {
    transform: rotateZ(0);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
