.informationCard {
  z-index: 1234;
  border-radius: 8px;
  background: #fcfcfd;
  padding: 16px;
}

.text {
  margin-bottom: 0;
  color: #121212;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
