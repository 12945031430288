.search {
  padding: 12px 0;
  width: 100%;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.05);
  border: 1px solid #636366;
  border-radius: 8px;
  background: #121212;
  padding: 10px 14px;
  padding-right: 4px;
  width: 100%;
  height: 48px;
  color: #d0d5dd;

  &Active {
    @extend .searchContainer;
    box-shadow: 0px 20px 24px -4px rgba(255, 255, 255, 0.18), 0px 8px 8px -4px rgba(255, 255, 255, 0.03);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.input {
  outline: 0;
  border: none;
  background: #121212;
  padding: 10px 0;
  padding-left: 0px;
  width: 100%;
  color: #d0d5dd;

  &::placeholder {
    color: #d0d5dd;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
