.wrapper {
  @apply box-border border w-full;
  border-color: transparent;
}

.borderedWrapper {
  @extend .wrapper;

  @apply rounded-lg border border-innerBorder py-4 px-6;
}

.content {
  @apply grid gap-8 items-end;
  grid-template-columns: 1fr auto;
}

.label {
  @apply text-sm flex items-center gap-2.5 mb-1.5;
  transition: 0.2s all;
}

.labelActive {
  @extend .label;

  @apply text-white;
}

.labelDisabled {
  @extend .label;

  @apply text-grey600;
}

.passwordInput {
  @apply text-white box-border w-full border rounded-lg border-border;
  background: #242424;
  padding: 10px 14px;
  outline: none;
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.05);
  height: 44px;
  transition: 0.2s all;
}

.passwordInputActive {
  @extend .passwordInput;

  &:focus {
    @apply border-white;
  }
}

.passwordInputDisabled {
  @extend .passwordInput;

  background: transparent;
}

.errorMessage {
  @apply text-error text-sm text-left mt-1.5;
}

.done {
  @apply flex items-center gap-2;
  @apply text-white font-medium text-sm;
  height: 44px;
}